import { PUSH_NOTIFICATION_REDIRECT_TYPE } from '~/enum/pushNotification';
import { translate } from '~/services/i18n';

export const getPushSegmentationOptions = (pushSegmentations: models.PushNotificationSegmentation[]) => {
  return pushSegmentations.map((o) => {
    return {
      name: o.title,
      value: o.value,
    };
  });
};

export const getPushRedirectTypeOptions = () => [
  {
    name: translate('PAGES.PANEL.PUSH.DETAILS.FORM.REDIRECT_TYPE.OPTIONS.NONE'),
    value: PUSH_NOTIFICATION_REDIRECT_TYPE.NONE,
  },
];
