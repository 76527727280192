import React from 'react';

import { Banner, NavbarLMS, Navbar, TopMenu } from '@portal/components';
import { useReduxState } from '@portal/hooks';
import { arrayAdminProfiles } from '@portal/enum';
import useWindowSize from '@portal/utils/getSizeWindow';
import { RenderCondition } from '@portal/utils';

interface IProps {
  top?: boolean;
  banner?: boolean;
  navbar?: boolean;
  ComponentWrapped: React.ReactNode;
}

const TopBottomDefaultTemplate: React.FC<IProps> = ({ top, banner, navbar, ComponentWrapped }) => {
  const isMobile = useWindowSize();

  const {
    auth: { me },
    brand,
  } = useReduxState();

  const isAdmin = me?.profileType && arrayAdminProfiles.includes(me?.profileType);

  const schoolBrand = me?.school?.schoolBrands?.find((schoolBrand) => schoolBrand?.idBrand === brand?.brandConfig?.id);

  const isNewNavbar = !!schoolBrand?.enableNewNavbar;

  return (
    <>
      <RenderCondition condition={top}>
        <RenderCondition condition={!isNewNavbar}>
          <Navbar />
        </RenderCondition>

        <RenderCondition condition={!!isNewNavbar}>
          <TopMenu />
        </RenderCondition>
      </RenderCondition>

      <div style={{ width: '100%', ...(isAdmin && !isNewNavbar && { paddingTop: isMobile ? '4rem' : '4.5rem' }) }}>
        {banner && <Banner />}
        {navbar && <NavbarLMS banner={banner} />}
        {ComponentWrapped}
      </div>
    </>
  );
};

export default TopBottomDefaultTemplate;
