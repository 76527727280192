/* eslint-disable @typescript-eslint/no-explicit-any */
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as AuthActions from '~/actions/auth';
import { Constants } from '~/config/env';
import * as CookieService from '~/services/cookie';

import Router from './Router';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const token = CookieService.getCookie('portal-token');

  const pathname = window?.location?.pathname;

  useEffect(() => {
    if (!token && !pathname?.includes('redirect')) {
      window.location.replace(`${Constants.portalUrl}/login`);
    }

    if (!pathname?.includes('redirect')) {
      dispatch(AuthActions.checkIsLogged(token));
    }
  }, [token, pathname]);

  const domain = window.location.hostname.split('.')[0];

  const clarity = (c: any, l: any, a: string, r: string, i: string) => {
    c[a] =
      c[a] ||
      function (...args: any) {
        (c[a].q = c[a].q || []).push(args);
      };
    const t = l.createElement(r);
    t.async = 1;
    t.src = `https://www.clarity.ms/tag/${i}`;
    const y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  };

  useEffect(() => {
    const domainsBlock = ['localhost', 'lmsdev', 'lmshmg'];

    if (domain && !domainsBlock.includes(domain)) {
      clarity(window, document, 'clarity', 'script', 'ebmps0f5ws');
    }
  }, [domain]);

  return <Router />;
};

export default App;
