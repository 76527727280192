import Compress from 'compress.js';

export const compressImages = async (image: File[]) => {
  let response = null;
  try {
    const compress = new Compress();

    response =  await compress.compress(image, {
      maxHeight: 1500,
      quality: 0.80,
      size: 1,
      resize: true,
    });

    response = response ? `${response[0].prefix}${response[0].data}` : null;
  } catch (error) {
    response = error;
  }

  return response;
};