/* eslint-disable max-len */
/* tslint:disable:object-literal-sort-keys */
const ptBr = {
  APPLICATION: {
    CURRENCY: 'BRL',
    ERRORS: {
      GENERIC: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      INVALID_KEY: 'Key do i18n inválida ou inexistente.',
      INSTANCE: {
        REFRESH_TOKEN: 'Token expirado. Faça Login novamente!',
        LOGIN: 'Faça Login novamente!',
      },
      USERS_NOT_FOUND: 'Não foi possível encontrar lista de usuários',
      TOKENS_NOT_SEND: 'Notificação não enviada',
      ENTITY_NOT_FOUND: 'Não foi possível encontrar o item buscado',
      USER_ALREADY_EXISTS: 'Usuário já existe',
      TABLE_RESTAURANT_ALREADY_EXISTS: 'Mesa já existe',
      READ_SPREADSHEET_ERROR: 'Não foi possível ler a planilha',
      FAILURE_PUSH_LIST: 'Ops! Parece que alguns CPF’s não receberam a notificação.',
      BLINK_LEARNING: 'Não foi possível completar o redirecionamento',
    },
    LANG: 'pt-BR',
  },
  COMPONENTS: {
    ADVANCED_FILTER: {
      FILTER: 'Filtrar',
      CLEAN: 'Limpar',
    },
    ADVANCED_FILE_UPLOAD: {
      ERROR: 'Por favor insira uma imagem no formato png ou jpg',
      FILENAME: 'Nome do arquivo:',
    },
    SEARCH_BAR: {
      SEARCH: {
        LABEL: 'o que deseja procurar?',
      },
      STATUS: {
        LABEL: 'STATUS',
      },
      FILTERS: {
        LABEL: 'FILTROS',
      },
    },
    DATA_TABLE_ACTIONS: {
      ADD: {
        LABEL: 'Adicionar',
      },
      EDIT: {
        LABEL: 'Editar',
      },
      VIEW: {
        LABEL: 'Visualizar',
      },
      DELETE: {
        LABEL: 'Deletar',
      },
      PRINT: {
        LABEL: 'Imprimir',
      },
      OPEN: {
        LABEL: 'Abrir',
      },
      HISTORY: {
        LABEL: 'Histórico',
      },
    },
    PRODUCT_LIST: {
      MARKETING: 'Portal do Marketing',
      EMPTY: 'Você ainda não possui recursos',
    },
    POPOVER_SCHOOL_MANAGEMENT: {
      USERS: 'USUÁRIOS',
      CLASSES: 'TURMAS',
      ADVISER_AREA: 'CONSULTORIA',
    },
    PANEL_CONTENT_TOP_BAR: {
      NAVBAR: {
        START: 'INÍCIO',
        FAQ: 'FAQ',
        CHAT: 'CHAT',
        NOTIFICATIONS: 'NOTIFICAÇÕES',
        PRODUCTS: 'RECURSOS',
        PROFILE: 'PERFIL',
        CLASSES: 'TURMAS',
        SCHOOL_MANAGEMENT: 'GESTÃO ESCOLAR',
        MARKETING: 'Portal do Marketing',
        LOGOUT: 'SAIR',
      },
      PAGES: {
        DASHBOARD: {
          TITLE: 'Dashboard',
          FAQ: 'FAQ',
          CHAT: 'Menssagens',
          HELP: 'Ajuda',
          BLOCK: 'Bloco',
          PORTAL: 'Portal Pleno',
          PROFILE: 'Meu perfil',
          LOGOUT: 'Sair',
        },
      },
    },
    CHAT_TOP_BAR: {
      INPUT_SEARCH: 'Pesquisar professores',
      CHAT: 'Chat',
    },
    FOOTER: {
      SOCIAL_MEDIA: {
        INSTAGRAM: 'Instagram',
        YOUTUBE: 'Youtube',
        LINKEDIN: 'LinkedIn',
        SLOGAN: '© Pleno, 2022. Não é sobre mim, é sobre nós.',
      },
    },
    SEARCHBAR: {
      PLACEHOLDER: 'Busque por nome ou tema"',
    },
    CARD: {
      CONCLUDED: 'Concluído!',
      PROGRESS: {
        PROGRESS: 'Progresso',
        PENDING_ACTIVITY: 'Atividades\nPendentes',
      },
      BUTTONS: {
        MORE_INFORMATION: 'Mais informações',
        ACCESS_CONTENT: 'Acessar conteúdo',
        SUBUSCRIBE: 'Matricule-se',
        MAX_CAPACITY: 'Estre grupo atingiu a capacidade máxima. Converse com o seu professor',
        ENROLLMENT_PERIOD_ENDED: 'Período de matrículas encerrado',
        AUTOMATIC_ENROLLMENT_NOT_AVAILABLE: 'Gostou desse conteúdo? Para cursá-lo, converse com o seu professor.',
        CERTIFICATION: 'Baixar certificado',
        CONTINUE: 'Continuar',
        SEE_TRAIL: 'Ver Trilha',
        SEE_ELECTIVE: 'Ver Eletiva',
        SEE_DETAILS: 'Ver detalhes',
      },
      MESSAGES: {
        UNAVAILABLE_CERTIFICATE: 'O certificado ainda não está disponível. Por favor tente novamente mais tarte.',
        WITHOUT_PROGRESS: 'Você não teve progresso suficiente para obter certificado.',
      },
    },
    ADVANCED_ACCORDION: {
      PENDING_TEXT: 'Você possuí %{pendingNumber} pendências',
      ACTIVITY_PENDING_TEXT: 'Unidade %{chapter} - Aula %{activity} |',
      ACTIVITY_PENDING_TEXT_DATE: 'Prazo %{date}',
      TEXT_BUTTON: 'Continuar atividades',
      LABEL_MODULE: 'Unidade %{moduleIndex} - Aula %{contentIndex} - Atividade %{activityIndex} ',
      LABEL_MODULE_ACTIVITY: 'Unidade %{moduleIndex} - %{activityIndex} ',
      LABEL_MODULE_CLASS: 'Unidade %{moduleIndex} - %{contentIndex}',
    },
    NAVIGATION_MENU: {
      HOME: 'Início',
      ALL_ELECTIVES: 'Todas as Eletivas',
      ALL_TRAILS: 'Todas as Trilhas',
      MY_ELECTIVES: 'Minhas eletivas',
      MY_TRAILS: 'Minhas trilhas',
      OTHER_ELECTIVES: 'Eletivas disponíveis',
      MY_GROUPS: 'Meus Grupos',
      TUTOR_CONTENT: 'Material de apoio para tutores',

      CONCLUDED_ELECTIIVES: 'Eletivas concluídas',
      CONCLUDED_TRAILS: 'Trilhas concluídas',
      CONCLUDED: 'Meus certificados',

      CONTINUE_STUDYING: 'Continuar Cursando:',
      PROGRESS: 'Carga horária anual - você já completou:',

      MOST_RECENT: 'Mais recentes',

      TECER: 'Tecer',
      TUTOR_DASH: 'Dashboard Tutor',

      BACK_TO_AVA: 'Voltar para o AVA',
    },
    NOTIFICATION_POPOVER: {
      EMPTY_NOTIFICATION: 'Você não tem notificações!',
    },
    SIDEBARCONTENT: {
      CARD: {
        PROGRESS: {
          PROGRESS_TEXT: 'Progresso',
          COMPLETED: '%{completed} de %{total} atividades completas',
        },
        SEE_MORE: 'Mais informações',
      },
      FINAL_TASK_DROPDOWN: 'Fechamento',
      FINAL_TASK_LABEL: 'Avaliação Final',
    },
    CLOSURE_TASK: {
      SUBMIT_BUTTON_TEXT: 'Enviar resposta',
      MESSAGES: 'Entrega final enviada com sucesso!',
      MESSAGES_TECER: 'Culminância enviada com sucesso!',
      STATUS: {
        DONE: 'Entrega final aprovada!',
        DONE_TECER: 'Culminância aprovada!',
        WAITING: 'Sua entrega está pendente para revisão.',
        REDO: 'É necessário refazer esta tarefa.',
      },
      LABEL_STATUS: 'Entrega Final',
    },
    SATISFACTION_SURVEY: {
      SUBMIT_BUTTON: 'Enviar respostas',
      MESSAGES: {
        ALREADY_DONE: 'A pesquisa de satisfação já foi realizada',
        REQUIRED_QUESTIONS: 'Todas as respostas são obrigatórias. Cheque os campos e tente novamente.',
        SUBMIT_SUCCESSFULLY: 'Pesquisa de satisfação enviada com sucesso! Obrigado.',
      },
    },
    RENDER_CONTENT: {
      PDF_LOADING_LABEL: 'Aguarde um pouquinho, conteúdo carregando...',
      DOWNLOAD_PDF: 'Clique aqui para baixar o material',
      DOWNLOAD_AUDIO: 'DOWNLOAD ÁUDIO',
      DOWNLOAD_ARCHIVE: 'DOWNLOAD DO ARQUIVO',
      DOWNLOAD_ANWSER: 'DOWNLOAD DA RESPOSTA',

      WELCOME: 'Olá, bem-vindo(a)!',
      DESCRIPTION: 'Para iniciar selecione um conteúdo no menu ao lado, ou clique em ”Próximo conteúdo”.',
    },
    GROUP_MODAL: {
      CANCEL_BUTTON: 'Cancelar',
      UPDATE_BUTTON: 'Atualizar cronograma do grupo',
    },
    DRAG_AND_DROP: {
      DROPZONE: {
        DROPZONE_LABEL: 'Clique aqui ou arraste um arquivo para subir',
        DRAG_ACTIVE: 'Solte o Arquivo aqui!',
      },
    },
  },
  PAGES: {
    AUTH: {
      LOGIN: {
        URL: '/',
        PAGE_TITLE: 'Entrar',
        MESSAGES: {
          LOGOUT: 'Você saiu do sistema.',
          WELCOME: 'Bem-vindo(a) novamente.',
          INVALID: 'Email e/ou senha incorretos.',
        },
        FORM: {
          TITLE: 'ENTRAR',
          MESSAGE:
            'Ut sit amet ultricies turpis, sed molestie eros. Praesent magna neque, elementum non semper vitae, vestibulum vitae mi.',
          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          PASSWORD: {
            LABEL: 'Senha',
            PLACEHOLDER: 'Seu senha segura',
          },
          BUTTON: {
            TEXT: 'Entrar',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Esqueceu sua senha?',
            TEXT_LINK: 'Clique aqui',
          },
        },
      },
    },
    HOME: {
      TITLE: 'Home',
      SCREEN: {
        TITLE: {
          FIRST_PART: 'Lista de atividades ',
          SECOND_PART: 'pendentes',
        },
        PENDING_ACTIVITIES: {
          EMPTY_STATE: 'Você não tem nenhuma atividade pendente.',
        },
        SEARCH_SECTION: {
          SEARCH: 'O que você quer estudar?',
          THEMATIC_AXIS: 'Eixo temático',
          KNOWLEDGE_AREA: 'Área de conhecimento',
        },
        FILTER_SECTION: {
          ALL: 'Todas',
          PROFESSION: 'Profissões',
          MAKER: 'Maker',
          SOCIOEMOTIONAL: 'Socioemocional',
          LANGUAGE: 'Língua',
        },
        ELECTIVES_SECTION: {
          TITLE: 'Suas eletivas',
        },
        TRAILS_SECTION: {
          TITLE: 'Suas trilhas',
        },
        TEXT_BUTTON: {
          TRAILS: 'Acessar todas suas trilhas',
        },
      },
      ELECTIVE_EMPTY_STATE: 'Você ainda não está matriculado em nenhuma eletiva.',
      TECER_EMPTY_STATE: 'Você ainda não está matriculado em nenhuma trilha.',
    },
    COURSES: {
      TITLE: 'Cursos',
      EMPTY_MY_COURSES: 'Você não tem conteúdos disponíveis.',
      EMPTY_SUBTITLE_MY_COURSES: 'Entre em contato com a sua escola.',
      EMPTY_SUBTITLE_MORE_MY_COURSES: 'Siga as intruções da página para realizar sua matrícula.',
      ASK_SUBSCRITION: 'Solicite que seu professor matricule você.',
      ASK_SUBSCRITION_TECER: 'Solicite que seu consultor matricule você.',
      EMPTY_OTHER_COURSES: 'Você não tem conteúdos disponíveis. Entre em contato com a sua escola.',
      EMPTY_FINISHED_COURSES: {
        TITLE: 'Meus certificados',
        EMPTY_TITLE: 'Você ainda não tem conteúdos concluídos',
        SUBTITLE: 'Ao concluí-los eles estarão aqui para você consultar o histórico e baixar seu certificado.',
        DOWNLOAD_CERTIFICATE: 'GERAR CERTIFICADO COM CARGA HORÁRIA TOTAL',
      },
      EMPTY_FINISHED_COURSES_TRAIL: {
        TITLE: 'Você ainda não concluiu nenhuma trilha',
        SUBTITLE: 'Ao concluí-las elas estarão aqui para você consultar o histórico e baixar seu certificado.',
      },
      SEARCH_BAR: {
        FILTER: {
          KNOWLEDGE_AREA: 'Área do conhecimento',
          THEMATIC_AXIS: 'Eixo temático',
          SEE_MORE: '+ ver mais',
        },
      },
      TITLES: {
        MY_ELECTIVES: 'Minhas eletivas',
        MY_TRAILS: 'Minhas trilhas',
        OTHER_ELECTIVES: 'Eletivas disponíveis',
        CONCLUDED_ELECTIIVES: 'Eletivas concluídas',
        CONCLUDED_TRAILS: 'Trilhas concluídas',
      },
      SUBTITLES: {
        MY_TRAILS: 'Conheça todas as trilhas oferecidas pela sua escola.',
      },
    },
    COURSE_DETAILS: {
      TITLE: 'Detalhes do curso',
      BACK: 'Voltar',
      ACCESS_CONTENT: 'Acessar conteúdo',
      NAVIGATION: {
        DETAILS: 'Descrição',
        PROGRAM: 'Ementa',
        SKILLS: 'Habilidades',
        CLOSURE_TASK_DESCRIPTION: 'Trabalho final',
        CLOSURE_TASK_DESCRIPTION_TECER: 'Trabalho final',
        TEST_DESCRIPTION: 'Avaliação Final',
        REFERENCE: 'Referência',
      },
      MODAL: {
        TITLE: 'Matrícula realizada com sucesso!',
        SUBTITLE: 'Vamos começar',
        BUTTON: 'Ir para o curso',
      },
    },
    GROUPS: {
      TITLES: {
        MY_GROUPS: 'Meus grupos',
      },
      SUBTITLES: {
        MY_GROUPS: 'Professor(a), abaixo estão os grupos em que você é tutor:',
      },
      SUBTITLES_FINISHED: {
        MY_GROUPS: 'Professor(a), abaixo estão os grupos em que você é tutor:',
      },
      EMPTY_GROUP: {
        EMPTY_ACTIVE: 'Você não possui nenhum grupo ativo.',
        EMPTY_FINISHED: 'Você não encerrou nenhum grupo.',
        EMPTY_FINISHED_TEXT: 'Assim que você encerrar algum grupo, poderá visualizar o histórico por aqui.',
      },
      GROUPCARD: {
        PENDENCES: 'pendências',
        INFOS: {
          GROUP: 'Grupo: ',
          REGISTRATION: 'Matrícula: ',
          REGISTRATIONS: 'Matrículas: ',
          VACANCIES: 'Vagas: ',
        },
        BUTTONS: {
          ENTER: 'Entrar',
          SEE_ELECTIVE: 'Ver Eletiva',
          SEE_TRAIL: 'Ver Trilha',
          SEE_DETAILS: 'Ver detalhes',
        },
        TOOLTIP: {
          EDIT_GROUP: 'Editar grupo',
          EDIT_SCHEDULE: 'Editar cronograma de matrículas',
          SHOW_STUDENTS: 'Exibir lista de alunos',
        },
      },
      GROUPS_MODAL: {
        SUBTITLE: 'Período de matrículas:',
        PARAGRAPH: 'Gerencie as datas de liberação de conteúdos de seu grupo',
        START_AT: 'Data de Inicio',
        END_AT: 'Data Final',
        CLOSURE_TASK: 'Trabalho Final',
        EVALUATION_FEEDBACK: 'Gabarito de avaliação',
        PLACE_HOLDER: 'Selecione a data',
        FINAL_DATE_BLOCK: {
          TITLE: 'Bloqueio de conteúdo pela data final:',
          DESCRIPTION:
            'Ao ligar o bloqueio de conteúdo pela data final, os alunos não conseguirão visualizar e executar atividades e avaliações, deste grupo, após o vencimento da data final estabelecida no cronograma abaixo.',
          OFF: 'Desligado',
          ON: 'Ligado',
        },
      },
      TABS: {
        ACTIVES: 'Ativos',
        FINISHED: 'Encerrados',
        BUTTON: {
          NEW_GROUP: 'Criar grupo novo',
        },
        NEW_GROUP_MODAL: {
          ERROR_MESSAGE: {
            NO_COURSE: 'Você deve selecionar um curso',
            NO_GROUPNAME: 'Você deve inserir o nome do grupo',
            NO_TUTOR: 'Você deve selecionar um tutor',
            NO_CAPACITY: 'Você deve inserir a capacidade',
          },
          GROUP_DATA: 'Dados do grupo',
          ENROLLMENT_METHOD: 'Método de matrícula',
          ENROLLMENT_PERMIT: 'Permitir matrículas automáticas',
          COURSE_SELECT: 'Selecione o curso',
          GROUP_NAME: 'Nome do grupo',
          TUTOR_SELECT: 'Selecione o tutor',
          CAPACITY: 'Capacidade',
          START_DATE: 'Data de início',
          END_DATE: 'Data de término',
          BUTTONS: {
            CANCEL: 'Cancelar',
            CREATE_GROUP: 'Criar grupo',
            EDIT_GROUP: 'Editar grupo',
          },
          SUCCESS_MESSAGE: 'Grupo criado com sucesso!',
          ENROLLMENT_MESSAGE:
            'A matrícula automática permite que alunos da escola consigam se inscrever em um curso de maneira automática, sem que seja necessário que algum tutor faça isso.',
        },
      },
      GROUPS_PAGE: {
        SUBTITLE: 'Grupo: ',
        AVAILABILITY: 'Vagas',
        CREATION_DATE: 'Data da Criação',
        CLOSE_DATE: 'Data do encerramento',
        CLOSEGROUP: 'Grupo Encerrado',
        BUTTONS: {
          FINISH: 'ENCERRAR GRUPO',
          SEND_REPORT: 'ENVIAR RELATÓRIO',
          TOOLTIP: 'Para encerrar o grupo é necessário que o coordenador configure o certificado.',
          ENROLL: 'MATRICULAR ALUNOS',
          TOOLTIP_DATE: 'Período de matrícula encerrado',
        },
        MODAL_SEND_REPORT: {
          TITLE: 'Relatório da eletiva',
          SUBTITLE: 'Grupo:',

          LABEL: 'Insira um e-mail para envio do relatório:',
          INPUT_PLACEHOLDER: 'E-mail',

          FEEDBACK_SUCCESS: {
            FEEDBACK: 'Relatório enviado com sucesso!',
            INFO: 'Verifique a sua caixa de entrada.',
          },

          FOOTER: {
            CANCEL: 'CANCELAR',
            SEND: 'ENVIAR',
            CLOSE: 'X FECHAR',
          },
        },
        MODAL_CLOSE: {
          TITLE: 'Encerrar grupo',
          SUBTITLE: 'IMPORTANTE!',
          DESCRIPTION: {
            PART_1:
              'Professor(a), ao encerrar um grupo, todos os alunos receberão automaticamente certificado ou declaração de horas.',
            PART_2:
              'Alunos que tenham contemplado a quantidade de conteúdos vistos, atividades realizadas e feito a entrega final, independentemente de nota, receberão certificado.',
            PART_3: 'Alunos que tenham algum desses itens a contemplar receberão uma declaração de horas.',
            PART_4: 'ESTA AÇÃO NÃO PODERÁ SER DESFEITA.',
          },
          CLOSE_QUESTION: 'Tem certeza que deseja encerrar o grupo?',
          BUTTON: {
            YES: 'Sim',
            NO: 'Não',
          },
        },
        MODAL_ADD: {
          TITLE: 'Matricular Alunos',
          BUTTON: {
            ADD: 'Matricular',
            CANCEL: 'Cancelar',
            FILTER: 'FILTRAR',
            CLEAR_FILTERS: 'Limpar Filtros',
          },
          COLUMN_TABLE: {
            NAME: 'Nome',
            EMAIL: 'E-mail',
            STUDENT_CLASS: 'Turma',
          },
          FILTERS: {
            EMAIL: 'E-mail',
            CLASSE: 'Turma',
            YEARS: 'Anos Escolares',
          },
        },
        MODAL_REMOVE: {
          TITLE: 'Confirmação',
          BUTTON: {
            REMOVE: 'Remover',
            CANCEL: 'Cancelar',
          },
        },
        MODAL_START_ONBOARDING: {
          TITLE: 'Bem-vindo(a) :)',
          DESCRIPTIONS: {
            STUDENT: 'Comece a sua jornada de conhecimento! Quer saber como? Confira o nosso tutorial!',
            TUTOR: 'Educador(a), aprenda a usar a plataforma durante suas tutorias! Confira nosso tutorial!',
            ELECTIVE:
              'Educador(a), seja bem-vindo(a) à fase de acompanhamento do curso e correção de atividades. Para aprender como revisar as atividades, consulte nosso tutorial.',
          },
          FOOTER: {
            SKIP: 'PULAR ESSA ETAPA',
            SEE_TUTORIAL: 'VER TUTORIAL',
          },
        },
        MODAL_STEPS_ONBOARDING: {
          DESCRIPTIONS: {
            STUDENT: {
              1: 'Em <i>"%{termCourse}"</i>, você encontrará todos os conteúdos oferecidos pela sua escola para a sua jornada. Em <i>"%{myCourses}"</i>, você encontra os cursos que está cursando, e em <i>"MEUS CERTIFICADOS"</i>, você encontra os cursos finalizados.',
              2: 'Abaixo, você verá os cursos oferecidos pela sua escola. Para obter mais informações sobre o curso, clique em <i>"Ver detalhes"</i>. Se gostar do curso, basta matricular-se, caso esteja disponível. Se não estiver, converse com seu professor.',
              3: 'E se precisar revisar este tutorial, basta clicar em <i>"ver tutorial"</i>, que estaremos aqui para ajudá-lo novamente! Obrigado.',
            },
            TUTOR: {
              1: 'Esta é a sua barra de navegação. Em <i>"MEUS GRUPOS"</i>, você encontra todos os grupos em que você é o(a) educador(a) tutor(a) dos cursos. Em <i>"TODAS AS %{termCourse}"</i>, estão listadas todas as eletivas oferecidas pela sua escola aos alunos.',
              MATERIAL: ' Você pode baixar material de apoio clicando em <i>"MATERIAL DE APOIO PARA TUTORES"</i>.',

              2: 'Na seção <i>"%{playlistTitle}"</i>, você encontrará videoaulas que o instruirão sobre o processo de se tornar um(a) educador(a) tutor(a) de eletivas.',
              3: `
              <div>
                <h1>Meus grupos</h1>
                <p>Aqui você encontrará cards de todos os grupos em que você é o(a) educador(a) tutor(a). É por meio desta seção que você poderá gerenciar todos os alunos matriculados e corrigir as atividades dos cursos. Os grupos são divididos entre <i>"Ativos"</i>, aqueles que estão atualmente em andamento, e <i>"Encerrados"</i>, que já foram finalizados.</p>
              </div>
              `,
              4: `
              <div>
                <h1>Card do grupo</h1>
                <p>No card do grupo, você consulta informações básicas, como nome do grupo, quantidade de alunos matriculados e número de total vagas.</p>
              </div>
              `,
              CARD_GROUP_BUTTONS: {
                SEE_DETAILS: {
                  LABEL: 'Ver detalhes',
                  DESCRIPTION:
                    'Visualização de informações da %{termCourseSingular}, tais como descrição, ementa, habilidades, etc.',
                },
                TIMELINE: 'Cronograma de matrícula e liberação de conteúdo.',
                STUDENTS: 'Gestão de alunos: relatórios de participação, gestão de certificados e declarações.',
                ENTER: 'Acompanhar o curso e corrigir as atividades dos alunos.',
              },

              5: `
              <div>
                <h1>Gestão do grupo</h1>
                <p>Para gerenciar o grupo e corrigir as atividades, utilize os botões destacados:</p>
              </div>
              `,
              6: 'E se precisar revisar este tutorial, basta clicar em <i>"ver tutorial"</i>, que estaremos aqui para ajudá-lo novamente! Obrigado.',
            },
            LMS_EDUCATOR: {
              1: 'Em <i>"%{termCourse}"</i>, você encontrará todos os cursos oferecidos, e em <i>"%{myCourses}"</i>, você encontrará os cursos que já está cursando.',
              2: `
              <div>
                <p>
                  Em <i>"MEUS CERTIFICADOS"</i>, você encontrará todos os cursos finalizados e poderá gerar certificados de cursos individuais. Além disso, você pode gerar um único certificado contendo todos os seus cursos, caso tenha finalizado mais de um.
                </p>
                <br/>
                <p>
                  Atenção, professor(a)! Os cursos possuem critérios para emissão do certificado, como a entrega de atividades e o acerto mínimo em avaliações. Em caso de dúvidas, converse com seu consultor.
                </p>
              </div>
              `,
              3: 'Abaixo, você encontrará os cursos organizados em trilhas. Essas trilhas estão disponíveis para ajudar na sua escolha, visando a progressão na sua carreira. Para saber mais sobre um curso e se matricular, clique em <i>"Ver detalhes"</i>.',
              4: 'E se precisar revisar este tutorial, basta clicar em <i>"Ver tutorial"</i>, que estaremos aqui para ajudá-lo novamente! Obrigado.',
            },
            ELECTIVE: {
              1: `
              <div>
                <h1>
                  Revisando atividades dos alunos
                </h1>
                <p>
                  Para revisar as atividades, acesse o módulo e selecione a atividade desejada. Você encontrará três abas: <i>Gabarito</i>, <i>Para revisão</i> e <i>Histórico</i>.
                </p>
              </div>
              `,
              2: `
              <div>
                <h1>
                  Gabarito
                </h1>
                <p>
                  Educador(a), aqui você encontra todas as respostas das atividades da etapa selecionada.
                </p>
              </div>
              `,
              3: `
              <div>
                <h1>
                  Para revisão
                </h1>
                <p>
                  Para corrigir as atividades respondidas pelos seus alunos, abra a aba <i>"Para revisão"</i>. Você verá o nome dos alunos que já responderam à atividade e aguardam revisão. Clique sobre o aluno desejado e faça a correção da atividade. Você pode aceitar a resposta ou solicitar uma nova resposta ao aluno.
                </p>
              </div>
              `,
              4: `
              <div>
                <h1>
                  Histórico
                </h1>
                <p>
                  Aqui são registradas todas as atividades respondidas e corrigidas.
                </p>
              </div>
              `,
              5: 'E se precisar revisar este tutorial, basta clicar em <i>"Ver tutorial"</i>, que estaremos aqui para ajudá-lo novamente! Obrigado.',
            },
          },
        },
        STATUS_ELECTIVE: {
          TITLE: 'Status da Eletiva',
          SUBTITLE: 'Unidade',
          GROUP: 'Grupo: ',
          STUDENT: 'Aluno: ',
          TABLE: {
            CLASSROOM: 'Aula',
            STATUS: 'Status',
            DATE: 'Data',
            DONE: 'Concluído',
            TODO: 'A fazer',
            ACTIVITY: 'Atividade',
          },
        },
        COLUMN_TABLE: {
          NAME: 'Nome',
          EMAIL: 'E-mail',
          CONTENT: 'Conteúdos vistos %',
          ACTIVITIES: 'Atividades realizadas %',
          CLOSURE: 'Status entrega final',
          TEST_GRADE: 'Nota da avaliação final',
          SURVEY: 'Pesquisa de Satisfação',
          POPCONFIRM: 'Deseja mesmo remover o estudante?',
          STATUS_ELECTIVE: {
            TITLE: 'Status da eletiva',
            TEXT: 'ver detalhes',
          },
        },
        REMOVE_STUDENTS: {
          MODAL: 'Tem certeza que deseja remover a matrícula de',
          START: 'Remover',
          STUDENT: 'estudante',
          STUDENTS: 'estudantes',
        },
      },
      GROUPS_VIDEO: {
        HEADER: 'Aprendendo a ser tutor',
        VIDEO_MODAL: {
          NEXT: 'Próximo',
          PREVIOUS: 'Anterior',
        },
      },
    },
    CHAT: {
      TITLE: 'Chat',
      MODAL: {
        TITLE: 'DÚVIDAS QUESTÃO',
        OK_TEXT: 'Entendido',
        CANCEL_TEXT: 'Fechar',
      },
      MESSAGE: {
        SEEN_AT: 'Visto às %{date}',
        SENT_AT: 'Visto às %{date}',
      },
      PHOTO: {
        FAIL_LOADING: 'Não foi possível carregar a imagem',
      },
      CONTACT: {
        ONLINE_NOW: 'Online agora',
      },
      INPUT: {
        TYPE_HERE: 'Escreva algo aqui',
      },
    },
    REDIRECT: {
      TITLE: 'Redirecionando',
      TEXT_TITLE: 'Matrícula efetuada com sucesso!',
    },
    PROFILE: {
      TITLE: 'Perfil',
      CHANGE_AVATAR: 'Alterar foto do perfil',
      FORM: {
        NAME: {
          LABEL: 'Seu nome completo',
        },
        EMAIL: {
          LABEL: 'E-mail',
        },
        SCHOOL: {
          LABEL: 'Escola',
        },
        CLASS: {
          LABEL: 'Turma',
        },
        CHANGE_PASSWORD: {
          LABEL: 'Trocar senha',
        },
      },
      CHANGE_PASSWORD: {
        VALIDATION: {
          CURRENT_PASS: 'É necessário preencher a senha atual',
          MIN_SIZE: 'A senha deve possuir no mínimo 6 dígitos',
          NEW_PASS: 'A nova senha deve ser preenchida.',
          REPEAT_PASS: 'É necessário repetir a nova senha',
          DIFFERENT_PASS: 'A confirmação de senha está diferente da nova senha',
        },
        SUCCESS: 'Senha atualizada com sucesso!',
        MODAL: {
          TITLE: 'Troca de senha',
          DESCRIPTION: 'Preencha os campos abaixo para a troca da sua senha:',
          FIELDS: {
            CURRENT_PASS: 'senha atual',
            NEW_PASS: 'nova senha',
            REPEAT_PASS: 'repetir senha',
          },
          OK_TEXT: 'Trocar senha',
          CANCEL_TEXT: 'Cancelar',
        },
      },
    },
    PANEL: {},
    CONTENT: {
      TITLE: 'Conteúdo',
      SIDEBAR: {
        DROPDOWN: {
          LABEL: {
            HEADER: 'Unidade %{index}',
            HEADER_TECER: 'Módulo %{index}',
          },
          DETAILS: {
            CLASS_LABEL: 'Aula %{index} - %{title}',
            ACTIVITY_LABEL: 'Atividade %{index}',
            TITLE: 'Conteúdo ainda não liberado',
            STATUS: {
              CONTENT: {
                PENDING: 'Conteúdo pendente',
                LATE: 'Conteúdo atrasado',
              },
              ACTIVITY: {
                PENDING: 'Atividade pendente',
                LATE: 'Atividade atrasada',
              },
            },
          },
        },
        FINAL_TASK_DROPDOWN: {
          LABEL: 'Atividades finais',
        },
      },
      ACTIVITY_SECTION: {
        STATUS: {
          WAITING: 'Sua atividade está pendente para revisão.',
          DONE: 'Atividade aprovada!',
          REDO: 'É necessário refazer esta atividade.',
        },
        DEADLINE_STATUS: {
          SAFE: 'O prazo para fazer esta atividade é até ',
          ATTENTION: 'Atenção! O prazo para fazer esta atividade é até ',
          MISSED: {
            TITLE: 'O prazo para realizar esta atividade era até ',
            SUBTITLE: 'Evite acumular conteúdos e finalize-a.',
          },
        },
        ANSWER_SECTION: {
          REQUIRED_FIELD_TEXT: 'Campo em destaque é obrigatório.',
          ANSWER_TEXT_TAB: 'Resposta',
          ANSWER_ATTACH_TAB: 'Anexos',
          ANSWER_ATTACH_INPUT: 'Arraste ou coloque o arquivo aqui',
          ANSWER_ATTACH_INPUT_DROP: 'Solte o arquivo aqui...',
          ANSWER_ATTACH_INPUT_INFO: '(Limite máximo de 25 mb)',
          ANSWER_TEXT_PLACEHOLDER: 'Digite sua resposta aqui...',
          SENDING_ATTACHMENT: 'Carregando o arquivo...',
          LINK: 'Clique aqui para acessar o anexo',
          SENT_ANSWER: 'Atividade respondida em ',
          EXPECTED_ANSWER: 'RESPOSTA ESPERADA: ',
          STUDENT_ANSWER: 'RESPOSTA DO ALUNO: ',
          ALTERNATIVE: 'alternativa ',
          TITLE_TEXT_AREA: 'Escreva um comentário para o aluno',
          EDIT_REVIEW_BUTTON: 'EDITAR REVISÃO',
          CHARACTERS: 'Caracteres: ',
        },
        RADIO_BUTTON: {
          YES: 'Sim',
          NO: 'Não',
          ANOTHER_ANSWER: 'Pedir outra resposta',
        },
        SUBMIT_BUTTON: 'Enviar',
        SAVE_BUTTON: 'Salvar',
        EDIT_BUTTON: 'Editar',
        SUBMIT_REVIEW: 'Enviar revisão',
        NEXT_ISSUE: 'PRÓXIMA PENDÊNCIA',
        TOOLTIPS: {
          CHAT: 'Tire sua dúvida com seu(sua) professor(a)',
        },
        FEEDBACK: {
          QUESTION_LINK: 'Tirar uma dúvida',
        },
        MESSAGES: {
          MAX_SIZE_REACHED: 'O arquivo excede o tamanho máximo de %{size} MB',
        },
        LOCKED_ACTIVITY: {
          LABEL_BEFORE: 'Este conteúdo estará',
          BOLD_LABEL_BEFORE: 'disponível em ',
          LABEL_AFTER: 'Prazo encerrado! Este conteúdo estava',
          BOLD_LABEL_AFTER: 'disponível até ',
          SATISFACTION_SURVEY: 'Você precisa visualizar todo o conteúdo para abrir a pesquisa de satisfação.',
          SATISFACTION_SURVEY_TECER: 'Esse conteúdo estará disponível ao final da trilha.',
        },
        LABEL_STATUS_CURRENT: 'Unidade %{unit} - Aula %{class} - Atividade %{activity}',
        LABEL_STATUS_CURRENT_ACTIVITY: 'Unidade %{unit} - %{activity}',
        LABEL_STATUS_CURRENT_CLASS: 'Unidade %{unit} - %{class}',

        TUTOR_SECTION: {
          SOLUTION: 'Gabarito',
          REVIEW: 'Para revisão',
          SAVE_COMMENT: 'Salvar',
          SEND_COMMENTS: 'Enviar revisão',
          HISTORY_REVIEW: 'Histórico de revisão',
          HISTORY_TEST: 'Histórico',
        },
        REVIEW_TUTOR: {
          SEND_SUCESS: 'Revisão enviada com sucesso!',
        },
      },
      FINAL_EVALUATION: {
        SIDEBAR: {
          TITLE: 'Avaliação final',
        },
        EVALUATION_STATUS: {
          WAITING: 'Sua avaliação está pendente para revisão.',
          DONE: 'Avaliação aprovada!',
          REDO: 'É necessário refazer esta avaliação.',
        },
        GRADE: 'Nota: %{grade}',
        SUBMIT_BUTTON: 'Salvar todas e enviar!',

        FEEDBACK: {
          HEADER: {
            BACK: 'voltar',
            CONTINUE: 'Continuar',
          },

          TITLE_POSITIVE: 'Parabéns!',
          TITLE_NEGATIVE: 'Oops!',

          MY_EVALUATION: 'Ver minha avaliação',

          DESCRIPTION_GRADE: 'Você acertou:',
          LABEL: 'Para emitir o certificado você precisa acertar %{grade}% da avaliação.',
          TRY_AGAIN: 'Tentar novamente',
        },

        QUESTIONS: {
          CORRECT_QUESTIONS: '*Selecione %{correctQuestions} opções.',
          SELECT_ONE: 'É necessário selecionar uma alternativa nesta questão',
          MODAL: {
            TITLE: 'Descrição da opção',
          },
        },

        MESSAGES: {
          SEND_SUCCESS: 'Avaliação enviada com sucesso!',
          PENDING_ANSWERS: 'Revise as respostas antes de enviar novamente.',
        },
        LABEL_STATUS: 'Avaliação final',
      },
      TEST: {
        SIDEBAR: {
          TITLE: 'Entrega final',
          TECER_TITLE: 'Trabalho final',
        },
        STATUS: {
          WAITING: 'Sua entrega está pendente para revisão.',
          DONE: 'entrega aprovada!',
          REDO: 'É necessário refazer esta entrega final.',
        },
        SUBMIT_BUTTON: 'Salvar todas e enviar!',
        MODAL: {
          DONE: {
            TITLE: 'Parabéns! Você foi aprovado',
            SUBTITLE: 'Sua nota: ',
          },
          REDO: {
            TITLE: 'Você não atingiu a pontuação suficiente!',
            SUBTITLE: 'Por favor, refaça a avaliação.',
          },
        },
      },
      SATISFACTION_INDEX: {
        SIDEBAR: {
          TITLE: 'Pesquisa de satisfação',
        },
      },
      NAVIGATION: {
        BACK: 'Conteúdo anterior',
        NEXT: 'Próximo conteúdo',
        MESSAGES: {
          CLOSURE_TASK_NOT_RELEASED: 'Entrega final ainda não está liberada',
          TEST_NOT_RELEASED: 'Avaliação final ainda não está liberada',
          SATISFACTION_SURVEY_NOT_RELEASED: 'Pesquisa de satisfação ainda não está liberada',
          NEXT_CONTENT_NOT_RELEASED: 'O próximo conteúdo ainda não está disponível.',
        },
      },
    },
  },

  SHARED: {
    YES: 'Sim',
    NO: 'Não',
    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
    LOADING: 'Carregando',
    NO_RESULT: 'Nenhum resultado',
    EDIT: 'Editar',
    UPDATE: 'Atualizar',
    DELETE: 'Deletar',
    EXCLUDE: 'Excluir',
    REMOVE: 'Remover',
    CLOSE: 'Fechar',
    ALERT: 'Atenção',
    IMPORTANT: 'Importante',
    INSERT: 'Inserir',
    CONTINUE: 'Continuar',
    NEXT: 'Próximo',
    PREVIOUS: 'Anterior',
    CREATE: 'Criar',
    CONECTED: 'Ligado',
    OFF: 'Desligado',
    OF: 'de',
    SAVE: 'Salvar',
    ADD: 'Adicionar',
    SEND: 'Enviar',
    ENTER: 'Entrar',
    ELECTIVETITLE: 'Eletivas',
    TECERTITLE: 'Trilhas',
    CURRENCY_SYMBOL: 'R$',
    SELECT_SOMETHING: 'Selecione...',
    ADD_NEW: 'Adicionar',
    IMPORT_NEW: 'Importar CSV',
    ADD_ID: 'registro',
    DAY: 'Dia',
    DELETE_ACTION: {
      TITLE: 'Deseja realmente excluir esse item?',
      CONTENT: 'Esta ação é irreversível',
    },
    DATETIME_FORMAT: 'dd/MM/yyyy hh:mm',
    SENDED: 'Enviado',
    PENDING: 'Pendente',
    SCHEDULED: 'Agendado',
    CANCELED: 'Cancelado',
    MESSAGES: {
      QUESTION_ANSWER: {
        UPLOAD_SUCCESSFULLY: 'Anexo carregado com sucesso!',
      },
      ACTIVITY_ANSWER: {
        CHECK_FIELDS: 'Revise os campos obrigatórios e tente novamente...',
        SUBMIT_SUCCESS: 'Atividade submetida com sucesso!',
      },
      SCHEDULE_UPDATE_SUCCESS: 'Agendamento atualizado com sucesso!',
    },
    API_ERROR_MESSAGES: {
      ENTITY_CANNOT_HAVE_DUPLICATES: 'Esta atividade já foi realizada.',
      ENTITY_NOT_FOUND: 'Não foi possível carregar o conteúdo.',
      SCHOOL_NOT_ALLOW_STUDENT_ENROLL:
        'É necessário solicitar a matrícula para acessar este curso. Entre em contato com seu mentor.',
      MISSING_AVAILABLE_VIRTUAL_GROUP: 'Matrícula esgotada para este curso. Entre em contato com seu mentor.',
      CONTENT_NOT_RELEASED: 'Conteúdo não disponibilizado.',
      FORBIDDEN: 'Acesso Negado.',
      GENERIC: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      TIMEOUT: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      INVALID_ID: 'ID inválido.',
    },
    API_MESSAGES: {
      INVALID_CURRENT_PASSWORD: 'A senha atual está incorreta.',
    },
  },
};

export default ptBr;
