import DateFnsUtils from '@date-io/date-fns';
//import LuxonUtils from '@date-io/luxon';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import { Settings } from 'luxon';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import { WL_COMPANY_PRIMARY_COLOR } from '~/config/env';
import { LANGUAGE } from '~/config/env';

import App from './App';
import languages from './i18n';
import createStore from './reducers/createStore';
import './styles/index.less';
import reportWebVitals from './reportWebVitals';
import { ReactQueryProvider } from '@portal/providers';

import '@arcotech-services/iris-react/css/index.css';

import '@arcotech-services/iris-tokens/theme-core.css';

import '@arcotech-services/iris-tokens/themes/iris-tokens-ways.css';
import { IrisProvider } from '@arcotech-services/iris-react';

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    newrelic: any;
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

window.newrelic = window.newrelic || {};

const store = createStore;

syncTranslationWithStore(store);
store.dispatch(loadTranslations(languages));
store.dispatch(setLocale(LANGUAGE));

Settings.defaultLocale = LANGUAGE;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: WL_COMPANY_PRIMARY_COLOR,
    },
  },
});

const localeMap = {
  pt: ptLocale,
};

ReactDOM.render(
  <React.StrictMode>
    <IrisProvider>
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap}>
              <ReactQueryProvider>
                <App />
              </ReactQueryProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </IrisProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
